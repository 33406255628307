import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginSignUpModal from "../LoginSignUpModal/LoginSignUpModal";
import Logo from "../../Assets/Images/nilhans-logo.png";

const Navbar = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState("");
  const location = useLocation();
  const navRef = useRef(null);

  const navItems = [
    { name: "Home", path: "/" },
    { name: "Deals", path: "/deals" },
    { name: "Blogs", path: "/blogs" },
    { name: "About Us", path: "/about" },
  ];

  useEffect(() => {
    const currentPath = location.pathname;
    const activeItem = navItems.find((item) => item.path === currentPath);
    if (activeItem) {
      setIsActive(activeItem.name.toLowerCase());
    } else {
      setIsActive("");
    }
  }, [location.pathname, navItems]);

  const handleLinkClick = (name) => {
    setIsActive(name.toLowerCase());
    const collapseElement = navRef.current;
    if (
      collapseElement &&
      window.getComputedStyle(collapseElement).display === "block"
    ) {
      collapseElement.classList.remove("show");
    }
  };

  return (
    <>
      <header>
        <div className="bg-theme-primary-light-2">
          <nav className="d-flex gap-3 justify-content-end container py-1">
            {/* <span>FAQs</span> */}
            {sessionStorage.getItem("login") ? (
              <span className="pointer" onClick={() => navigate("/dashboard")}>
                My Account
              </span>
            ) : (
              <span
                className="pointer"
                data-bs-toggle="modal"
                data-bs-target="#login_modal"
              >
                Sign in
              </span>
            )}
          </nav>
        </div>
        <nav className="navbar navbar-expand-lg navbar-light py-2">
          <div className="container">
            <Link
              className="navbar-brand text-primary-theme text-uppercase fw-bold"
              to="/"
            >
              <img
                src={Logo}
                className="img-fluid mx-auto d-block pointer"
                style={{ height: "68px" }}
                alt="logo"
                loading="lazy"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
              ref={navRef}
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-3 me-2 text-center">
                {navItems.map((item) => (
                  <li className="nav-item" key={item.name}>
                    <Link
                      className={
                        isActive === item.name.toLowerCase()
                          ? "nav-link py-1 active"
                          : "nav-link py-1"
                      }
                      to={item.path}
                      onClick={() => handleLinkClick(item.name)}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="d-flex justify-content-center">
                <span className="btn theme-btn-primary btn-rounded">
                  Contact
                </span>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <LoginSignUpModal />
    </>
  );
};

export default Navbar;
